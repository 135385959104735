// React Basic and Bootstrap
import React, { Component } from "react";
import Link from "../components/Link";
import { Row, Col } from "reactstrap";
// Import Css
import "../Apps.scss";
import "../css/materialdesignicons.min.css";
// import images
import img404 from "../images/404.svg";

class PageError extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="back-to-home rounded d-none d-sm-block">
          <Link
            to="/"
            className="text-white rounded d-inline-block text-center"
          >
            <i className="mdi mdi-home"></i>
          </Link>
        </div>

        <section className="bg-home">
          <div className="home-center">
            <div className="home-desc-center">
              <div className="container">
                <Row className="justify-content-center">
                  <Col lg={8} md={12} className="text-center">
                    <img
                      src={img404}
                      className="img-fluid"
                      alt="Page not found"
                    />
                    <div className="text-capitalize text-dark mt-4 mb-4 error-page">
                      Página no encontrada
                    </div>
                    <p className="text-muted para-desc mx-auto">
                      Parece que lo que buscabas ya no se encuentra aquí.{" "}
                      <span className="text-primary font-weight-bold">
                        Vuelve a la página principal
                      </span>{" "}
                      pulsando en el siguiente botón.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <div className="col-md-12 text-center">
                    <Link to="/" className="btn btn-outline-primary mt-4">
                      Ir a la página principal
                    </Link>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default PageError;
